import React from 'react';
import Container from '../Container';
import AnchorLink from '../Link';
import Icon from '../Icons';
import { useGaEvent } from '../../hooks/hooks.ga-events';

const Contacts = ({ contactsRows, marginBottom, marginTop }) => {
  const rows = [...contactsRows];

  return (
    <Container
      className="contacts"
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      <div className="contacts__wrapper">
        {rows && rows.map((row) => {
          const { title, hasBorder } = row;
          // TODO: Set hasBorder in DatoCMS

          return (
            <div className={`contacts__container ${hasBorder ? '' : 'contacts__container--no-border'}`}>
              {title && <h2 className="contacts__title heading-md">{title}</h2>}
              {
                row.contacts && row.contacts.map((contact) => {
                  const {
                    title, richText, linkMap, linkFacebook, linkTwitter, linkInstagram
                  } = contact;
                  if (richText || linkMap || linkFacebook || linkTwitter || linkInstagram) {
                    return (
                      <Contact
                        title={title}
                        richText={richText}
                        linkMap={linkMap}
                        linkFacebook={linkFacebook}
                        linkTwitter={linkTwitter}
                        linkInstagram={linkInstagram}
                      />
                    );
                  }
                  return null;
                })
              }
            </div>
          );
        })}
      </div>
    </Container>
  );
};

Contacts.defaultProps = {
  contactsRows: []
};

const Contact = ({
  title, richText, linkMap, linkFacebook, linkTwitter, linkInstagram
}) => {
  const handleGaEvent = (url, social) => {
    useGaEvent('Social Network Click-out', `Content Detail Page::${social}`, url);
  };
  return (
    <div className="contacts__content">
      <span className="contacts__title-info label-small">{title}</span>
      <div className="contacts__paragraph paragraph-small" dangerouslySetInnerHTML={{ __html: `${richText}` }} />
      {linkMap
        && (
          <AnchorLink
            kind="secondary"
            label="Vedi sulla mappa"
            color="brand"
            disabled={false}
            className="contacts__link"
            url={linkMap}
            target
          />
        )}
      {
        (linkFacebook || linkTwitter || linkInstagram) && (
          <ul className="contacts__social-list">
            {linkFacebook
              && (
                <li className="widget-social__item" key={linkFacebook}>
                  <a
                    href={linkFacebook}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleGaEvent(linkFacebook, 'Facebook')}
                  >
                    <Icon className="widget-social__icon icon--36" path="social-ico-facebook" />
                  </a>
                </li>
              )}
            {linkTwitter
              && (
                <li className="widget-social__item" key={linkTwitter}>
                  <a
                    href={linkTwitter}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleGaEvent(linkTwitter, 'Twitter')}
                  >
                    <Icon className="widget-social__icon icon--36" path="social-ico-twitter" />
                  </a>
                </li>
              )}
            {linkInstagram
              && (
                <li className="widget-social__item" key={linkInstagram}>
                  <a
                    href={linkInstagram}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleGaEvent(linkInstagram, 'Instagram')}
                  >
                    <Icon className="widget-social__icon icon--36" path="social-ico-instagram" />
                  </a>
                </li>
              )}
          </ul>
        )
      }
    </div>
  );
};

export default Contacts;
