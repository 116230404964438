import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../containers/layout';
import HeaderPage from '../components/HeaderPage';
import Paragraph from '../components/Paragraph';
import VisualComposer from '../components/VisualComposer';
import Contacts from '../components/Contacts';
import Accordion from '../components/Accordion';
import AccordionList from '../components/AccordionList';
import Container from '../components/Container';
import FeedWithPagination from '../components/FeedWithPagination';

const SingleProvince = ({ data, pageContext }) => {
  const {
    sede,
    slug,
    title,
    mappa,
    orari,
    twitter,
    content,
    regione,
    contatti,
    facebook,
    subtitle,
    instagram,
    presidente,
    segretario,
    seoMetaTags,
    vicepresidente,
  } = data.datoCmsProvince;

  const localNews = data.allDatoCmsLocalNews ? data.allDatoCmsLocalNews.nodes : [];

  const { slugArchive } = { slugArchive: data.datoCmsComitatiLocaliArchive.slug };

  const tempRegion = {
    ...regione,
    slug: `${slugArchive}/${regione.slug}`,
  };

  const contactsRows = [];
  const contactFirstRow = {
    title: 'Informazioni',
    hasBorder: true,
    contacts: []
  };

  const contactSecondRow = {
    title: 'Descrizione',
    contacts: []
  };

  const sedeBlock = {
    title: 'Sede',
    richText: sede,
    linkMap: mappa
  };

  const orariBlock = {
    title: 'Orari',
    richText: orari,
  };

  const contattiBlock = {
    title: 'Contatti',
    richText: contatti,
    linkFacebook: facebook,
    linkTwitter: instagram,
    linkInstagram: twitter
  };

  const presidenteBlock = {
    title: 'Presidente',
    richText: presidente,
  };

  const segretarioBlock = {
    title: 'Segretario',
    richText: segretario
  };

  const vicepresidenteBlock = {
    title: 'Vicepresidente',
    richText: vicepresidente,
  };

  contactsRows.push(contactFirstRow);
  contactsRows.push(contactSecondRow);

  contactFirstRow.contacts.push(sedeBlock);
  contactFirstRow.contacts.push(orariBlock);
  contactFirstRow.contacts.push(contattiBlock);

  contactSecondRow.contacts.push(presidenteBlock);
  contactSecondRow.contacts.push(segretarioBlock);
  contactSecondRow.contacts.push(vicepresidenteBlock);

  const { province } = { province: data.allDatoCmsProvince.nodes };

  return (
    <Layout
      seoMetaTags={seoMetaTags}
      pathname={pageContext.pathname}
      communicationBar={pageContext.communicationBar}
      pageType="Local Commitee"
      canonical={pageContext.canonical}
    >
      <HeaderPage
        title={title}
        model={regione.title}
        labelParent={tempRegion}
        parentPathname={`${pageContext.parentPathname}/${regione.slug}`}
      />
      {
        (sede || contatti || presidente || segretario || vicepresidente) && (
          <Contacts contactsRows={contactsRows} />
        )
      }
      {
        content && (
          <div className="page-wrapper">
            <div className="page-wrapper__paragraph">
              <Paragraph
                subtitle={subtitle}
                content={content}
                marginTop="zero"
                marginBottom="zero"
              />
            </div>
          </div>
        )
      }
      <Container
        className="comitati__accordions"
        marginTop="grande"
        marginBottom="grande"
      >
        <div className="comitati__accordions-wrapper">
          <div className="comitati__accordion">
            <Accordion isActive title="Comitati Locali">
              <AccordionList
                activeItem={slug}
                list={province}
              />
            </Accordion>
          </div>
        </div>
      </Container>
      {
        data && data.datoCmsRegioni && (
          <VisualComposer
            components={data.datoCmsRegioni.visualComposer}
          />
        )
      }
      {
        localNews.length > 0 && (
          <FeedWithPagination
            slugArchive={slugArchive}
            allNews={localNews}
          />
        )
      }
    </Layout>
  );
};

export default SingleProvince;

export const SINGLEPROVINCE_QUERY = graphql`
  query SingleProvinceQuery($id: String!, $idRegion: String!) {
    allDatoCmsLocalNews(
        filter: {comitato: {id: {eq: $id}}},
        sort: {fields: publicationDate, order: DESC}
    ) {
      nodes {
        id
        title
        slug
        publicationDate
        comitato {
          id
          title
          slug
        }
      }
    }
    datoCmsProvince(id: {eq: $id}) {
      id
      title
      slug
      sede
      mappa
      orari
      seoMetaTags {
        tags
      }
      regione {
        id
        slug
        title
      }
      contatti
      facebook
      instagram
      twitter
      presidente
      segretario
      vicepresidente
      subtitle
      content
      visualComposer {
        ...BannerText
        ...CenteredNumbers
        ...Numbers
      }
    }
    datoCmsComitatiLocaliArchive {
      slug
      title
    }
    allDatoCmsProvince(filter: {regione: {id: {eq: $idRegion}} }) {
      nodes {
        title
        slug
        regione {
          id
        }
      }
    }
  }
`;
