import React, { useState, useRef } from 'react';
import ListCardsText from '../ListCardsText';
import Pagination from '../Pagination';
import { useChangeDisplayPagesOnResize } from '../../hooks/archive-hooks';

const FeedWithPagination = ({ allNews, slugArchive }) => {
  const [results, setResults] = useState(allNews);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayPages, setDisplayPages] = useState(4);

  const section = useRef(null);

  const handleChangePage = (pageResults, page) => {
    setResults(pageResults);
    setCurrentPage(page);
  };

  useChangeDisplayPagesOnResize(setDisplayPages);

  return (
    <section ref={section} className="feed-w-pagination">
      <div className="feed-w-pagination__title-container">
        <h2 className="feed-w-pagination__title heading-md">News ed aggiornamenti</h2>
      </div>
      <div className="feed-w-pagination__container ">
        { results.length > 0 && <ListCardsText slugArchive={slugArchive} cards={results} /> }
      </div>
      <div className="feed-w-pagination__pagination-wrapper">
        { allNews && allNews.length > 12
          && (
            <Pagination
              scrollTop={section.current ? section.current.offsetTop : 0}
              items={allNews}
              currentPage={currentPage}
              itemsPerPage={12}
              displayPages={displayPages}
              handleChangePage={handleChangePage}
            />
          )}
      </div>
    </section>
  );
};

export default FeedWithPagination;
