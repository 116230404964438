import React from 'react';
import CardText from '../CardText';

const ListCardsText = ({ cards, slugArchive }) => {
  return (
    <div className="list-cards-text">
      {
        cards && cards.map((card) => {
          const { title, id } = card;
          const date = card.publicationDate;
          const label = card.comitato.title;
          const url = `/${slugArchive}/${card.comitato.slug}/${card.slug}`;
          return (
            <CardText
              key={id}
              label={label}
              title={title}
              date={date}
              url={url}
            />
          );
        })
      }
    </div>
  );
};

ListCardsText.defaultProps = {
  cards: [
    {
      label: 'Sostienici',
      title: 'Allattare per salvare vite, nel Myanmar flagellato dalle alluvioni',
      date: '21/05/2020'
    },
    {
      label: 'Sostienici',
      title: 'Allattare per salvare vite, nel Myanmar flagellato dalle alluvioni',
      date: '21/05/2020'
    }
  ]
};

export default ListCardsText;
