import React, { useState, useEffect } from 'react';

/**
 * Component used to handle pagination inside the archive pages.
 */
const Pagination = ({
  items,
  handleChangePage,
  itemsPerPage,
  currentPage,
  displayPages,
  scrollTop
}) => {
  const [pager, setPager] = useState({});

  const handleGetPager = () => {
    /** Default values */
    const totalItems = items.length;
    let startPage;
    let endPage;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const halfDisplayPages = (displayPages / 2);
    const currentPageInTheFirstHalf = currentPage <= halfDisplayPages + 1;
    const currentPageInTheLastShift = currentPage + halfDisplayPages - 1 >= totalPages;

    if (totalPages <= displayPages) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPageInTheFirstHalf) {
      /** If the current page is in the first half shift to left */
      startPage = 1;
      endPage = displayPages;
    } else if (currentPageInTheLastShift) {
      /** If the current page is in the last range shift to the end */
      startPage = totalPages - halfDisplayPages - 1;
      endPage = totalPages;
    } else {
      /** Shift to correct range */
      startPage = currentPage - halfDisplayPages;
      endPage = currentPage + halfDisplayPages - 1;
    }

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems - 1);
    /** The range of pages to display */
    const pages = [...Array((endPage + 1) - startPage).keys()].map((i) => startPage + i);

    return {
      totalItems,
      currentPage,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
      totalPages
    };
  };

  const handleSetPage = (page) => {
    if (page < 1 || page > pager.totalPages) {
      /** Check if the selected page is in the range of available pages */
      return;
    }
    /** Until the pager state is not updated I'm using a temporary obj */
    const tempPager = handleGetPager();
    /** Pick the queried range of items */
    const paginationResults = items.slice(tempPager.startIndex, tempPager.endIndex + 1);
    setPager(tempPager);
    /** Passing the results and the current page to an external component  */
    handleChangePage(paginationResults, page);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    }
    setTimeout(() => {
      if (items) {
        handleSetPage(currentPage);
      }
    }, 500);
    /** Fires every times items, currentPage or displayPages are changing */
  }, [items, currentPage, displayPages]);

  return (
    <div className="pagination">
      <div className="pagination__line" />
      <div className="pagination__list">
        <button type="button" className="pagination__item" onClick={() => handleSetPage(pager.currentPage - 1)}>
          <span type="button" className="pagination__btn label-small">PREV</span>
        </button>
        <ul className="pagination__list pagination__list--numbers">
          {pager.pages && pager.pages.map((page) => (
            <li key={page} className={`pagination__item ${pager.currentPage === page ? 'pagination__item--active' : ''}`}>
              <button
                type="button"
                className="pagination__btn caption"
                onClick={() => handleSetPage(page)}
              >
                {page}
              </button>
            </li>
          ))}
        </ul>
        <button type="button" className="pagination__item" onClick={() => handleSetPage(pager.currentPage + 1)}>
          <span className="pagination__btn label-small">
            NEXT
          </span>
        </button>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  items: [],
  handleChangePage: () => {},
  itemsPerPage: 10,
  currentPage: 1,
  displayPages: 10,
  scrollTop: 0
};

export default Pagination;
