import React, { useEffect, useState } from 'react';
import Link from '../TransitionLink';

const AccordionList = ({ list, activeItem }) => {
  const [active, setActive] = useState('');
  useEffect(() => {
    setActive(activeItem);
  }, [activeItem]);

  return (
    <ul className="accordion__list">
      {
        list && list.map((item, index) => {
          const { id, title, slug } = item;
          const isActive = active === slug;
          return (
            <li className="accordion__item paragraph-small" key={`${id}-${index}`}>
              <Link
                to={`/comitati-locali/${slug}/`}
                className={`accordion__link-city ${isActive ? 'accordion__link-city--active' : ''}`}
              >
                {title}
              </Link>
            </li>
          );
        })
      }
    </ul>
  );
};

export default AccordionList;
